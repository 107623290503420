<template>
  <div class="bg-ligtGary">
    <TopNav/>

    <div class="header header-cart">
      <div class="w w1200">
        <div class="logo">
          <div class="logoImg">
            <router-link :to="{path:'/'}">
              <img :src="require('@/assets/co/' + co + '/logo.png')">
            </router-link>
          </div>
          <div class="tit">购物车（<em>{{sumNums}}</em>）</div>
        </div>
        <div class="dsc-search">
          <div class="form">
            <input autocomplete="off" v-model="keywords" type="text" class="search-text">
            <input type="hidden" name="store_search_cmt" value="0">
            <button type="submit" class="button button-icon" v-on:click="search"><i></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="w w1200" v-loading="cartLoading" element-loading-text="拼命加载中......">
        <div class="cart-empty" v-if="cartList.length <= 0" >
          <div class="cart-message">
            <div class="txt">购物车快饿瘪了，主人快给我挑点宝贝吧</div>
            <div class="info">
              <router-link :to="{path:'/'}" class="btn sc-redBg-btn" tag="a">马上去逛逛</router-link>
            </div>
          </div>
        </div>
        <div class="cart-warp" v-else>
          <div class="cart-filter">
            <div class="cart-stepflex">
              <div class="cart-step-item curr">
                <span>1.我的购物车</span>
                <i class="iconfont icon-arrow-right-alt"></i>
              </div>
              <div class="cart-step-item">
                <span>2.成功提交订单</span>
              </div>
            </div>
            <div class="sp-area store-selector">

            </div>
          </div>
          <div class="cart-table">
            <div class="cart-head">
              <div class="column c-checkbox">
                <div class="cart-checkbox cart-all-checkbox">
                  <input type="checkbox" id="cart-selectall" class="ui-checkbox checkboxshopAll">
                  <label for="cart-selectall" class="ui-label-14">全选</label>
                </div>
              </div>
              <div class="column c-goods">商品</div>
              <div class="column c-props"></div>
              <div class="column c-price">单价（元）</div>
              <div class="column c-quantity">数量</div>
              <div class="column c-sum">小计</div>
              <div class="column c-action">操作</div>
            </div>
            <div class="cart-tbody">
              <div class="cart-item" v-for="(good,index) in cartList" v-bind:key="good.id">
                <div class="item-list">
                  <div class="item-single">
                    <div class="item-item">
                      <div class="item-form">
                        <div class="cell s-checkbox">
                          <div class="cart-checkbox" ectype="ckList">
                            <input type="checkbox" :id="'checkItem_'+good.id" :value="index" v-model="goodsSelected" name="checkItem" class="ui-checkbox">
                            <label :for="'checkItem_'+good.id" class="ui-label-14">&nbsp;</label>
                          </div>
                        </div>
                        <div class="cell s-goods">
                          <div class="goods-item">
                            <div class="p-img">
                              <router-link :to="{path:'goods',query:{id:good.goodsId}}" target="_blank" tag="a">
                                <img :src="JSON.parse(good.images)[0].imageUrl" width="70" height="70">
                              </router-link>
                            </div>
                            <div class="item-msg">
                              <router-link :to="{path:'goods',query:{id:good.goodsId}}" target="_blank" tag="a">
                                {{good.name}}
                              </router-link>
                              <div class="gds-types">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="cell s-props">
                          &nbsp;
                        </div>
                        <div class="cell s-price">
                          <strong><em>¥</em>{{good.price}}</strong>
                        </div>
                        <div class="cell s-quantity">
                          <amount-box v-model="good.num" :defaultVal="good.num" :max="99999" :min="1"></amount-box>
                        </div>
                        <div class="cell s-sum">
                          <strong>
                            <font><em>¥</em>{{parseFloat(good.num * good.price).toFixed(2)}}</font>
                          </strong>
                        </div>
                        <div class="cell s-action">
                          <a href="javascript:void(0);" class="cart-remove" v-on:click="deleteCart(good.id)">删除</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cart-tfoot">
              <div class="cart-toolbar">
                <div class="w w1200">
                  <div class="cart-checkbox cart-all-checkbox">
                    <input type="checkbox" id="toggle-checkboxes-down" class="ui-checkbox checkboxshopAll">
                    <label for="toggle-checkboxes-down" class="ui-label-14">全选</label>
                  </div>
                  <div class="toolbar-right">
                    <div class="comm-right">
                      <div class="btn-area">
                        <el-button class="submit-btn" @click.native="submitCart">提交订单</el-button>
                      </div>
                      <div class="price-sum" id="total_desc">
                        <span class="txt">总价：</span>
                        <span class="price sumPrice" id="cart_goods_amount"><em>¥</em>{{sumPrice}}</span>
                      </div>
                      <div class="amount-sum">已选择<em class="cart_check_num">{{sumNums}}</em>件商品</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w w1200" >
        <guess-love cssName="p-panel-main c-history" :count="6"></guess-love>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from '@/components/footer/Index'
import AmountBox from '@/components/AmountBox/Index'
import GuessLove from '@/components/GuessLove/Index'
import TopNav from '@/components/TopNav/Index'
import {deleteCart} from '@/api/user'
import {getAddressList} from "@/api/address";
import {orderCreate} from "@/api/mall/order";
import {getDetial} from "@/api/good";
// import settings from '@/utils/settings'
export default {
name: "Buy",
  data() {
    return {
      cartList:[],
      goodsSelected:[],
      sumNums: 0,
      sumPrice: 0,
      keywords: '',
      cartLoading:false,

      consigneeInfo: null,
    }
  },
  components: {
    Footer,
    AmountBox,
    GuessLove,
    TopNav
  },
  created() {
    this.getCartList()

    this.getAddress()
  },
  watch: {
    cartList:{
      handler:function(){
        this.calSum()
      },
      deep:true//对象内部的属性监听，也叫深度监听
    },
    goodsSelected:{
      handler:function(){
        this.calSum()
      },
      deep:true//对象内部的属性监听，也叫深度监听
    }
  },
  methods:{
    getCartList(){
      this.cartLoading = true

      let id = this.$route.query.id
      let _this = this;

      getDetial(id).then(res => {
        let cart = {}
        cart.cartId = "0";
        cart.goodsId = res.data.id;
        cart.goodsName = res.data.name;
        cart.images = res.data.images;
        cart.num = this.$route.query.num;
        cart.paramsData = res.data.paramsData;
        if(res.data.paramsData!=null){
          cart.params = JSON.parse(res.data.paramsData);
        }else{
          cart.params = []
        }
        cart.price = res.data.price;
        cart.specInfo = '默认';


        _this.cartList.push(cart);

        this.cartList.forEach((val,index)=>{
          this.goodsSelected.push(index)
        });

        this.cartLoading = false
      })

      // if(this.$route.query.id){
      //   id = this.$route.query.ids
      // }
      //
      // getCartList({'ids':ids}).then(res => {
      //   this.cartList = res.data.list.filter(function(val){
      //     return val.isSelect
      //   })
      //
      //   this.cartList.forEach((val,index)=>{
      //     if(val.products.images.indexOf("image.yabyy.com") == -1){
      //       val.products.images = settings.AdminUrl + val.products.images
      //     }
      //     //默认全部选中
      //     this.goodsSelected.push(index)
      //   })
      //
      //   this.cartLoading = false
      //
      // })
    },
    calSum(){
      let allNums = 0
      let allPrice = 0
      let _this = this
      this.goodsSelected.forEach(function (val){
        allNums = allNums + _this.cartList[val].num
        allPrice = allPrice + _this.cartList[val].num * _this.cartList[val].price
      });
      this.sumNums = allNums
      this.sumPrice = parseFloat(allPrice).toFixed(2)
    },
    submitCart(){
      // let _this = this
      // if(this.goodsSelected.length > 0){
      //   let carts = []
      //   this.goodsSelected.forEach(function (val){
      //     carts.push(_this.cartList[val].id)
      //   });
      //   createOrder({
      //     areaId: 320412,   // 区域id
      //     cartIds: carts.toString(),   // 购物车id
      //     couponCode: "",   // 优惠券编码 默认空
      //     groupId: 0,       // 团购秒杀序列
      //     memo: "",         // 备注
      //     point: 0,         // 积分
      //     receiptType: 2,   // 收货方式,1快递物流，2同城配送，3门店自提
      //     source: 2,        // 来源平台
      //     teamId: 0,        // 团队序列
      //     ushipId: 2300     // 用户地址库序列
      //   }).then(res => {
      //     if(res.status){
      //       this.$router.push({path:'/cartSuccess',query:{id:res.data.orderId}});
      //     }else{
      //       this.$message({
      //         message: res.msg,
      //         type: 'success'
      //       });
      //     }
      //   })
      // }
      let _this = this
      if(this.goodsSelected.length > 0){
        let carts = []
        this.goodsSelected.forEach(function (val){
          carts.push(_this.cartList[val])
        });
        if(carts.length > 0){
          orderCreate({
            carts : carts,
            note : '',
            buyer : this.consigneeInfo
          }).then(function(res){
            if(res.code == 200){
              _this.$router.push({path:'/cartSuccess',query:{id:res.data}});
            }else{
              _this.$message({
                message: res.msg,
                type: 'success'
              });
            }
          }).catch(function (err) {
            console.log(err)
          });
        }
      }
    },
    deleteCart(id){
      this.$confirm('此操作将删除购物车该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteCart({id:id}).then(res => {
          this.$message({
            type: 'success',
            message: res.msg
          });
          this.getCartList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    search(){
      let routerJump = this.$router.resolve({ path: '/search', query: { 'keywords':this.keywords } })
      window.open(routerJump.href, '_blank')
    },
    getAddress: function () {
      let _this = this;
      getAddressList().then(function(res){
        _this.consigneeInfo = res.data[0];
      }).catch(function (err) {
        console.log(err)
      });
    },
  }
}
</script>

<style scoped>
.submit-btn{
  line-height: 0;
  border-radius: 0;
}
.submit-btn:hover,.submit-btn:focus {
  background-color: #f42424;
  color: #fff;
}
.item-form .s-quantity {
  width: 90px !important;
  padding: 0px 38px 0 0 !important;
}
</style>
